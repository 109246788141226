import { render, staticRenderFns } from "./SecondPage.vue?vue&type=template&id=20b713cd&scoped=true&"
import script from "./SecondPage.vue?vue&type=script&lang=js&"
export * from "./SecondPage.vue?vue&type=script&lang=js&"
import style0 from "./SecondPage.vue?vue&type=style&index=0&lang=css&"
import style1 from "./SecondPage.vue?vue&type=style&index=1&id=20b713cd&lang=scss&scoped=true&"
import style2 from "./SecondPage.vue?vue&type=style&index=2&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20b713cd",
  null
  
)

export default component.exports